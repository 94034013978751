<template>
   <div id="splitted-portal" class="container-fluid">
      <div class="container">
         <div class="row py-3 px-4">
            <div class="col-lg-12 bg-image">
               <div class="row">
                  <div class="col liner-background d-flex align-items-center justify-content-center">
                     <img :src="require('@/assets/images/previews/dark/hotel-logo.svg')" alt="" class="hotel-logo" />
                  </div>
                  <div class="provider-components col-lg-4 col-sm-12 bg-dark-gray "></div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: 'SplittedLayout',
   props: {
      mode: {
         type: String,
         default: 'light',
      },
   },

   data() {
      return {};
   },
};
</script>
<style lang="scss" scoped>
.col-lg-4 {
   -ms-flex: 412px !important;
   flex: 412px !important;
   max-width: 400px !important;
}
@media (max-width: 768px) {
   .col-lg-4 {
      -ms-flex: 412px !important;
      flex: 412px !important;
      max-width: 100% !important;
   }
}
.hotel-logo {
   z-index: 10;
}

.liner-background {
   background: linear-gradient(295.63deg, #2f77e4 3.07%, rgba(47, 119, 228, 0.46) 100%);
   border-radius: 5px 0 0 5px;
}
.input-components {
   border-radius: 0 5px 5px 0;
   height: 760px;
}
.bg-dark-gray {
   background: #1c1e21;
}
.bg-image {
   margin: auto;
   float: none;
   background-size: 100%;
   background-repeat: repeat-y;
   border-radius: 5px;
   margin-top: 3%;
   margin-bottom: 3%;
}
</style>
